
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { Api } from "@/assets/Api";

export default defineComponent({
  name: "AdminSMS",
  components: {},
  setup() {
    let data = reactive<any>({
      dialogFormVisible: false,
      form: {
        phone: "",
      },
      SMSList: [],
    });

    const getList = () => {
      if (data.form.phone.length != 11) {
        Api.Msg.warning("请输入手机号码");
        return;
      }
      Api.Api2.SMSList(data.form.phone).then((res: any) => {
        console.info(res);
        if (res.code == "0") {
          data.SMSList = res.data;
        }
      });
    };

    return {
      ...toRefs(data),
      getList,
    };
  },
});
