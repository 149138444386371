import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form, {
      inline: true,
      model: _ctx.form,
      class: "demo-form-inline"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, { label: "手机号码" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.form.phone,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.phone) = $event)),
              placeholder: "请输入手机号码"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _ctx.getList
            }, {
              default: _withCtx(() => [
                _createTextVNode("查询")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"]),
    _createVNode(_component_el_table, {
      ref: "table",
      data: _ctx.SMSList,
      border: "",
      style: {"width":"100%"},
      height: "80vh",
      "row-key": "SId",
      stripe: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "SId",
          label: "编号",
          width: "100"
        }),
        _createVNode(_component_el_table_column, {
          prop: "ClientId",
          label: "通道/渠道",
          width: "100"
        }),
        _createVNode(_component_el_table_column, {
          prop: "Phone",
          label: "手机号",
          width: "200"
        }),
        _createVNode(_component_el_table_column, {
          prop: "Content",
          label: "短信内容"
        }),
        _createVNode(_component_el_table_column, {
          prop: "SmsCount",
          label: "发送条数",
          width: "100"
        }),
        _createVNode(_component_el_table_column, {
          prop: "SendTime",
          label: "发送时间",
          width: "200"
        })
      ]),
      _: 1
    }, 8, ["data"])
  ]))
}